import React from "react"
import Topic from "../../components/Topic"

const LegalAid = () => (
  <Topic id="legal_aid">
    <p>
      The legal system in Canada is complex. It is advisable to seek
      professional legal advice for legal matters. In this section, you will
      learn about legal services that are available to you, with a focus in
      Ontario. Other provinces provide similar programs as well.{" "}
      <strong>
        Click{" "}
        <a
          href="https://www.justice.gc.ca/eng/fund-fina/gov-gouv/aid-aide.html"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>{" "}
        for legal aid programs for each province.
      </strong>
    </p>
    <p>
      If you need help to pay for a lawyer you might be eligible for financial
      help from{" "}
      <strong>
        <a href="http://www.legalaid.on.ca/" target="_blank" rel="noreferrer">
          Legal Aid Ontario
        </a>{" "}
        (LAO). LAO
      </strong>{" "}
      provides legal help for{" "}
      <a
        href="https://www.legalaid.on.ca/more/corporate/about-lao-landing-page/legal-aid-ontarios-financial-eligibility-guidelines/"
        target="_blank"
        rel="noreferrer"
      >
        financially eligible
      </a>{" "}
      low-income Ontarians, providing high-quality legal services in family law;
      refugee and immigration law; criminal law and mental health law. If you
      qualify and have a legal matter that the program covers, you need to apply
      for the Legal Aid certificate. When you contact a lawyer, it is important
      to say you have applied for or have received Legal Aid services. Not all
      lawyers accept Legal Aid certificates.
    </p>
    <p>
      <strong>Community Legal Clinics (CLCs)</strong> can give you free or
      low-cost legal information, advice and representation. CLCs offer a
      variety of legal services, including:
    </p>
    <ul className="topic__list">
      <li>free consultation and information</li>
      <li>information and services to help you prepare for your case</li>
      <li>
        full representation by a clinic advocate (who may be a lawyer, community
        legal worker or law student)
      </li>
      <li>
        referral to a private practitioner, duty counsel or community agency
      </li>
      <li>public legal education and information</li>
    </ul>
    <p>
      CLCs provide services in specific areas of law, such as landlord-tenant
      disputes, employment law, refugee and immigration law, and more. Contact{" "}
      <a
        href="http://www.legalaid.on.ca/legal-clinics/"
        target="_blank"
        rel="noreferrer"
      >
        your local CLC
      </a>{" "}
      to find out if they deal with the area of law that you need. If they do
      not, they will try to refer you to an agency that does.
    </p>
    <p>
      <a
        href="https://stepstojustice.ca/resource/justicenet/"
        target="_blank"
        rel="noreferrer"
      >
        JusticeNet
      </a>{" "}
      is a not-for-profit organization that helps people access legal services
      when their income is too high to qualify for legal aid, but too low to
      afford standard legal fees. The legal professionals listed with the
      JusticeNet charge at a reduced rate.
    </p>
    <p>
      Click on the links or watch the videos to learn more about how you can get
      legal advice and services for free through the legal aid program in
      Canada.
    </p>
  </Topic>
)

export default LegalAid
